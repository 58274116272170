import React, { useState, useEffect } from 'react';
import SocialIcons from './SocialIcons';
import './SplashPage.css';

const SplashPage: React.FC = () => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 4000); // Delay in milliseconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="splash-page">
      <div className={`hero-section ${showContent ? 'show-content' : ''}`}>
        <div className="background-image" />
        {showContent && (
          <div className="hero-content">
<p>Welcome to BlockATL, where blockchain meets artistry in an unparalleled fusion of innovation and creativity. Discover a vibrant ecosystem where technology transcends traditional boundaries, unleashing boundless possibilities for artistic expression and transformative change. Explore, create, and revolutionize with us today.</p>
            <SocialIcons />
            <div className="footer-content">
              <p>&copy; {new Date().getFullYear()} BlockATL. All rights reserved.</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SplashPage;
